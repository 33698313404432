import React from "react";
import { useTranslation } from "react-i18next";

import Footer from "../../components/Footer";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full px-6 md:px-28 lg:px-56 py-20 bg-black text-white">
      <h1 className="pt-8 text-xl md:text-3xl text-center font-light">{t("pages.privacy.title")}</h1>
      <h3 className="mt-6 text-md font-extralight">{t("pages.privacy.updated")}</h3>
      <div className="space-y-16 font-light">
        <div className="space-y-6 mt-6">
          <h2 className="font-extrabold  text-xl">{t("pages.privacy.introduction.title")}</h2>
          <p>{t("pages.privacy.introduction.text1")}</p>
          <p>{t("pages.privacy.introduction.text2")}</p>
          <p>{t("pages.privacy.introduction.text3")}</p>
          <p>{t("pages.privacy.introduction.text4")}</p>
        </div>
        <div className="space-y-6 mt-6">
          <h2 className="font-extrabold  text-xl">{t("pages.privacy.collecting.title")}</h2>
          <p>{t("pages.privacy.collecting.text1")}</p>
          <p>{t("pages.privacy.collecting.text2")}</p>
          <a href="mailto:privacy@trackap.com"><p className="text-center font-bold">privacy@trackap.com</p></a>
          <p>{t("pages.privacy.collecting.text3")}</p>
        </div>
        <div className="space-y-6 mt-6">
          <h2 className="font-extrabold  text-xl">{t("pages.privacy.processing.title")}</h2>
          <p>{t("pages.privacy.processing.text1")}</p>
          <p>{t("pages.privacy.processing.text2")}</p>
          <p>{t("pages.privacy.processing.text3")}</p>
        </div>
        <div className="space-y-6 mt-6">
          <h2 className="font-extrabold  text-xl">{t("pages.privacy.processing2.title")}</h2>
          <p>{t("pages.privacy.processing2.text1")}</p>
          <p>{t("pages.privacy.processing2.text2")}</p>
          <p>{t("pages.privacy.processing2.text3")}</p>
          <ul className="space-y-6">
            <li className="list-disc list-inside">{t("pages.privacy.processing2.text4")}</li>
            <li className="list-disc list-inside">{t("pages.privacy.processing2.text5")}</li>
            <li className="list-disc list-inside">{t("pages.privacy.processing2.text6")}</li>
            <li className="list-disc list-inside">{t("pages.privacy.processing2.text7")}</li>
          </ul>
          <ul>
            <p>{t("pages.privacy.processing2.text8")}</p>
            <li className="list-inside ml-6">{t("pages.privacy.processing2.text9")}</li>
            <li className="list-inside ml-6">{t("pages.privacy.processing2.text10")}</li>
            <li className="list-inside ml-6">{t("pages.privacy.processing2.text11")}</li>
            <li className="list-inside ml-6">{t("pages.privacy.processing2.text12")}</li>
          </ul>
          <p>{t("pages.privacy.processing2.text13")}</p>
          <p>{t("pages.privacy.processing2.text14")}</p>
          <p>{t("pages.privacy.processing2.text15")}</p>
        </div>
        <div className="space-y-6 mt-6">
          <h2 className="font-extrabold  text-xl">{t("pages.privacy.cookies.title")}</h2>
          <p>{t("pages.privacy.cookies.text1")}</p>
          <p>{t("pages.privacy.cookies.text2")}</p>
          <p>{t("pages.privacy.cookies.text3")}</p>
          <p>{t("pages.privacy.cookies.text4")}</p>

          <div className="space-y-6">
            <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-4">
              <div className="w-full md:w-1/2 flex-col md:flex-row border border-gray-300 divide-y divide-gray-300">
                <div className="w-full text-center">
                  {t("pages.privacy.cookies.firefox.title")}
                </div>
                <div className="w-full px-4 py-4">
                  <ul className="space-y-2">
                    <li className="list-disc list-inside">{t("pages.privacy.cookies.firefox.li1")}</li>
                    <li className="list-disc list-inside">{t("pages.privacy.cookies.firefox.li2")}</li>
                    <li className="list-disc list-inside">{t("pages.privacy.cookies.firefox.li3")}</li>
                    <li className="list-disc list-inside">{t("pages.privacy.cookies.firefox.li4")}</li>
                  </ul>
                </div>
              </div>
              <div className="w-full md:w-1/2 flex-col md:flex-row border border-gray-300 divide-y divide-gray-300">
                <div className="w-full text-center">
                  {t("pages.privacy.cookies.IE.title")}
                </div>
                <div className="w-full px-4 py-4">
                  <ul className="space-y-2">
                    <li className="list-disc list-inside">{t("pages.privacy.cookies.IE.li1")}</li>
                    <li className="list-disc list-inside">{t("pages.privacy.cookies.IE.li2")}</li>
                    <li className="list-disc list-inside">{t("pages.privacy.cookies.IE.li3")}</li>
                    <li className="list-disc list-inside">{t("pages.privacy.cookies.IE.li4")}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-4">
              <div className="w-full md:w-1/2 flex-col md:flex-row border border-gray-300 divide-y divide-gray-300">
                <div className="w-full text-center">
                  {t("pages.privacy.cookies.chrome.title")}
                </div>
                <div className="w-full px-4 py-4">
                  <ul className="space-y-2">
                    <li className="list-disc list-inside">{t("pages.privacy.cookies.chrome.li1")}</li>
                    <li className="list-disc list-inside">{t("pages.privacy.cookies.chrome.li2")}</li>
                    <li className="list-disc list-inside">{t("pages.privacy.cookies.chrome.li3")}</li>
                    <li className="list-disc list-inside">{t("pages.privacy.cookies.chrome.li4")}</li>
                    <li className="list-disc list-inside">{t("pages.privacy.cookies.chrome.li5")}</li>
                    <li className="list-disc list-inside">{t("pages.privacy.cookies.chrome.li6")}</li>
                    <li className="list-disc list-inside">{t("pages.privacy.cookies.chrome.li7")}</li>
                    <li className="list-disc list-inside">{t("pages.privacy.cookies.chrome.li8")}</li>
                  </ul>
                </div>
              </div>
              <div className="w-full md:w-1/2 flex-col md:flex-row border border-gray-300 divide-y divide-gray-300">
                <div className="text-center">
                  {t("pages.privacy.cookies.safari.title")}
                </div>
                <div className="px-4 py-4">
                  <ul className="space-y-2">
                    <li className="list-disc list-inside">{t("pages.privacy.cookies.safari.li1")}</li>
                    <li className="list-disc list-inside">{t("pages.privacy.cookies.safari.li2")}</li>
                    <li className="list-disc list-inside">{t("pages.privacy.cookies.safari.li3")}</li>
                    <li className="list-disc list-inside">{t("pages.privacy.cookies.safari.li4")}</li>
                    <li className="list-disc list-inside">{t("pages.privacy.cookies.safari.li5")}</li>
                    <li className="list-disc list-inside">{t("pages.privacy.cookies.safari.li6")}</li>
                    <li className="list-disc list-inside">{t("pages.privacy.cookies.safari.li7")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="space-y-6 mt-6">
          <h2 className="font-extrabold  text-xl">{t("pages.privacy.protection.title")}</h2>
          <p>{t("pages.privacy.protection.text1")}</p>
          <p>{t("pages.privacy.protection.text2")}</p>
          <p>{t("pages.privacy.protection.text3")}</p>
          <p>{t("pages.privacy.protection.text4")}</p>
          <p>{t("pages.privacy.protection.text5")}</p>
          <p>{t("pages.privacy.protection.text6")}</p>
        </div>
        <div className="space-y-6 mt-6">
          <h2 className="font-extrabold text-xl">{t("pages.privacy.accessData.title")}</h2>
          <p>{t("pages.privacy.accessData.text1")}</p>
          <p>{t("pages.privacy.accessData.text2")}</p>
        </div>
        <div className="space-y-6 mt-6">
          <h2 className="font-extrabold  text-xl">{t("pages.privacy.rights.title")}</h2>
          <p>{t("pages.privacy.rights.text1")}</p>
          <ul className="ml-6">
            <li>{t("pages.privacy.rights.text2")}</li>
            <li>{t("pages.privacy.rights.text3")}</li>
            <li>{t("pages.privacy.rights.text4")}</li>
            <li>{t("pages.privacy.rights.text5")}</li>
            <li>{t("pages.privacy.rights.text6")}</li>
            <li>{t("pages.privacy.rights.text7")}</li>
            <li>{t("pages.privacy.rights.text8")}</li>
            <li>{t("pages.privacy.rights.text9")}</li>
          </ul>
          <p>{t("pages.privacy.rights.text10")}</p>
        </div>
        <div className="space-y-6 mt-6">
          <h2 className="font-extrabold text-xl">{t("pages.privacy.getInTouch.title")}</h2>
          <p>{t("pages.privacy.getInTouch.text1")}</p>
          <p>{t("pages.privacy.getInTouch.text2")}</p>
          <a href="mailto:privacy@trackap.com"><p className="text-center font-bold">privacy@trackap.com</p></a>
          <p>{t("pages.privacy.getInTouch.text3")}</p>
        </div>
        <div className="space-y-6 mt-6 text-center pb-32">
          <p>{t("pages.privacy.sas")}</p>
          <p>{t("pages.privacy.address")}</p>
        </div>
      </div>
      <Footer background />
    </div>
  )
}

export default PrivacyPolicy;