import React, {useEffect,useRef,useState} from 'react';
import {Helmet} from 'react-helmet';
import Faq from 'react-faq-component';

// Components
import Forms from '../components/ContactForm';
import Footer from '../components/Footer';

// Images
import useIntersection from '../_hooks/useIntersection';
import Carousel from 'react-multi-carousel';

//images
import appointment from '../_images/oem/OEM1.png';
import factory from '../_images/oem/OEM2.png';
import meetupEnglish from '../_images/oem/OEM3.png';
import meetupMobileEnglish from '../_images/oem/OEM-mobile.png';
import meetupFrench from '../_images/oem/OEM3-fr.png';
import meetupMobileFrench from '../_images/oem/OEM-mobile-fr.png';
import smartBike from '../_images/oem/smartbike.png';

// Partners logos
import bikle from '../_images/oem/brands/bikle.png';
import carbo from '../_images/oem/brands/carbo.png';
import cyclik from '../_images/oem/brands/cyclik.png';
import elwing from '../_images/oem/brands/elwing.png';
import gitane from '../_images/oem/brands/gitane.png';
import solex from '../_images/oem/brands/solex.png';
import velobecane from '../_images/oem/brands/velobecane.png';
import voltaire from '../_images/oem/brands/voltaire.png';
import zosh from '../_images/oem/brands/zosh.webp';
import neomouvLogo from '../_images/oem/brands/neomouv.png';
import anod from '../_images/oem/brands/anod.png';
import vefaa from '../_images/oem/brands/vefaa.png';
import douze from '../_images/oem/brands/douze.png';
import peugeot from '../_images/oem/brands/peugeot.png';
import pragma from '../_images/oem/brands/pragma.png';
import cityq from '../_images/oem/brands/cityq.png';

// Apps images
import trackap from '../_images/oem/apps/trackap.webp';
import neomouv from '../_images/oem/apps/neomouv.webp';
import peugeotApp from '../_images/oem/apps/peugeot.webp';
import whitelabel from '../_images/oem/apps/whitelabel.webp';
import paad from '../_images/oem/apps/paad.webp';

import {responsive} from '../_helpers/responsiveCarousel';
import {useTranslation} from 'react-i18next';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Images = [
  {name : 'Neomouv', src : neomouvLogo, href : "https://neomouv.fr/"},
  {name : 'Gitane', src : gitane, href : "https://www.cycles-gitane.fr"},
  {name : 'Peugeot', src : peugeot, href : "https://cycles.peugeot.com/"},  
  {name : 'Douze', src : douze, href : "https://douze-cycles.com/"},
  {name : 'Anod', src : anod, href : "https://anod.com/"},
  {name : 'Pragma Industries', src : pragma, href : "https://pragma-mobility.com/"},
  {name : 'Voltaire', src : voltaire, href : "https://voltaire.bike/"},
  {name : 'Vefaa', src : vefaa, href : "https://vefaa.bike/"},
  {name : 'Zosh', src : zosh, href : "https://www.zosh-electric.com/"},
  {name : 'CityQ', src : cityq, href : "https://www.cityq.biz/"},
  
  {name : 'Elwing', src : elwing, href : "https://elwing.co/"},
  {name : 'Cyclik', src : cyclik, href : "https://cyclik.fr/e-relief/"},
  {name : 'Bikle', src : bikle, href : "https://bikle.fr/"},
  {name : 'Solex', src : solex, href : "https://solex.world/"},
  {name : 'Carbo', src : carbo, href : "https://ridecarbo.com/"},
  {name : 'Vélobecane', src : velobecane, href : "https://velobecane.com/"},
];

const apps = [
  {name: "Whitelabel", src : whitelabel},
  {name: "Paad", src : paad},
  {name : 'Trackap', src : trackap},
  {name: "Peugeot", src : peugeotApp},
  {name: "Neomouv", src : neomouv},
]

const OEM = () => {
  const [selectedSection, setSelectedSection] = useState(0);
  const {t} = useTranslation();

  // region Intersection Observer
  const content1Ref = useRef();
  const content2Ref = useRef();
  const content3Ref = useRef();
  const content4Ref = useRef();
  const content5Ref = useRef();
  const formsRef = useRef();

  const content1Visible = useIntersection(content1Ref, '-40%');
  const content2Visible = useIntersection(content2Ref, '-40%');
  const content3Visible = useIntersection(content3Ref, '-40%');
  const content4Visible = useIntersection(content4Ref, '-40%');
  const content5Visible = useIntersection(content5Ref, '-40%');
  const formsVisible = useIntersection(formsRef, '-40%');

  useEffect(() => {
    if (content1Visible) setSelectedSection(0);
    if (content2Visible) setSelectedSection(1);
    if (content3Visible) setSelectedSection(2);
    if (content4Visible) setSelectedSection(3);
    if (content5Visible) setSelectedSection(4);
    if (formsVisible) setSelectedSection(5);
  }, [
    content1Visible,
    content2Visible,
    content3Visible,
    content4Visible,
    content5Visible,
    formsVisible
  ]);
  // endregion

  const sections = [
    {text : 'pages.oem.menu.one', ref : content1Ref},
    {text : 'pages.oem.menu.two', ref : content2Ref},
    {text : 'pages.oem.menu.three', ref : content3Ref},
    {text : 'pages.oem.menu.four', ref : content4Ref},
    {text : 'pages.oem.menu.five', ref : content5Ref},
    {text : 'general.contactUs', ref : formsRef}
  ];

  const scrollSmoothHandler = (ref) => {
    ref.current.scrollIntoView({behavior : 'smooth'});
  };

    // region SEO bullshit
    const structuredData = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
      "@type": "Question",
      "name": t('pages.home.oem.faq.q1'),
      "acceptedAnswer": {
        "@type": "Answer",
        "text": t('pages.home.oem.faq.q1'),
      }
      },{
        "@type": "Question",
        "name": t('pages.home.oem.faq.q1'),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t('pages.home.oem.faq.q1'),
        }
      }]
    }
  
    const faqTitleStyle = {
      fontSize : "1.25rem",
      fontFamily : "Colfax-Medium"
    }
  
    const data = {
      title: t('pages.home.faq.title'),
      rows: [
          {
            title: <h2 style={faqTitleStyle}>{t('pages.oem.faq.q1')}</h2>,
            content: t('pages.oem.faq.a1'),
          }, {
            title: <h2 style={faqTitleStyle}>{t('pages.oem.faq.q2')}</h2>,
            content: t('pages.oem.faq.a2'),
          }, {
            title: <h2 style={faqTitleStyle}>{t('pages.oem.faq.q3')}</h2>,
            content: t('pages.oem.faq.a3'),
          },
      ],
    };
  
  const styles = {
      bgColor: 'black',
      titleTextColor: "white",
      rowTitleColor: "white",
      rowContentColor: 'white',
      arrowColor: "#cdff00",
  };
  
  const config = {
      arrowIcon: "x",
  };
  // endregion

  return (
    <div className="relative w-screen bg-black">
      {/* SEO OPTIMIZATION ELEMENTS */}
      <Helmet>
        <title>{t('pages.oem.head.htmTitle')}</title>
        <link rel="canonical" href={t('pages.oem.head.canonical')}/>
        <meta name="description" content={t('pages.oem.head.desc')} />
      </Helmet>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(structuredData),
        }}
      />

      {/* region MENU  */}
      <div className="fixed z-20 flex hidden h-screen w-1/4 text-2xl lg:block">
        <div className="absolute top-1/2 left-1/2 flex -translate-y-1/2 -translate-x-1/2 flex-col space-y-8 font-light">
          {sections.map((section, index) => (
            <div
              key={index}
              className={classNames(
                selectedSection === index ? 'text-primary font-medium' : 'text-white',
                'transform cursor-pointer transition duration-500 ease-in-out hover:translate-x-6 hover:text-primary lg:text-lg xl:text-xl'
              )}
              onClick={() => {
                setSelectedSection(index);
                scrollSmoothHandler(section.ref);
              }}
            >
              {t(section.text)}
            </div>
          ))}
        </div>
      </div>
      {/*endregion*/}

      <div className="flex w-full flex-col p-8 md:p-20" ref={content1Ref}>
        <h1 className="wow slideInDown pt-20 text-center text-3xl font-bold uppercase text-white md:text-6xl md:leading-[5rem] lg:pt-8">
          {t('pages.oem.title')}
        </h1>

        {/* region CONTENT ONE */}
        <div className="py-28 flex w-full justify-end">
          <div className="relative w-[100%] lg:w-3/4">
            <div className="flex flex-col gap-8">

              <div className="flex flex-col lg:flex-row">
                <div className="wow slideInRight flex w-full items-center pr-5 lg:w-2/3">
                  <p
                    className="text-sm text-white sm:text-lg md:text-sm xl:text-xl"
                    dangerouslySetInnerHTML={{
                      __html : t('pages.oem.contents.one')
                    }}
                  />
                </div>

                <div className="w-full sm:flex sm:justify-center sm:items-center md:w-1/2 lg:w-1/2">
                  <div className="wow slideInRight mt-4 w-full sm:mt-4 sm:w-2/3 md:mt-0 md:w-full">
                    <img src={appointment} alt={t('pages.oem.imgAlt')}/>
                  </div>
                </div>
              </div>

              <div className="carousel-oem wow fadeIn pt-4 lg:pt-0 w-full">
                <div className="carousel-track-oem flex gap-12">
                  {Images.map((item, index) => {
                    return (
                      <div className="image-carousel-oem" key={index}>
                        <a href={item.href} target="_blank"><img src={item.src} alt={item.name}/></a>
                      </div>
                    );
                  })}
                  {Images.map((item, index) => {
                    return (
                      <div
                        className="image-carousel-oem"
                        key={index + `--` + item.name}
                      >
                        <a href={item.href} target="_blank"><img src={item.src} alt={item.name}/></a>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*endregion*/}

        {/* region CONTENT TWO */}
        <div
          className="py-28 flex w-full justify-end"
          ref={content2Ref}
        >
          <div className="relative w-[100%] lg:w-3/4">
            <div className="flex flex-col gap-8">
              <img
                src={localStorage.getItem("i18nextLng") === 'fr' ? meetupMobileFrench : meetupMobileEnglish}
                alt=""
                className="block sm:hidden"
              />
              <img
                src={localStorage.getItem("i18nextLng") === 'fr' ? meetupFrench : meetupEnglish}
                alt=""
                className="hidden sm:block"
              />
            </div>

          </div>
        </div>
        {/*endregion*/}

        {/* region CONTENT THREE */}
        <div
          className="py-28 flex w-full justify-end"
          ref={content3Ref}
        >
          <div className="relative w-[100%] lg:w-3/4">
            <div className="flex flex-col lg:flex-row">
              <div className="wow slideInRight w-full lg:w-1/2 flex items-center">
                <p
                  className="text-sm text-white sm:text-lg md:text-sm xl:text-xl"
                  dangerouslySetInnerHTML={{
                    __html : t('pages.oem.contents.three')
                  }}
                />
              </div>
              <div className="w-full sm:flex sm:justify-center md:w-2/3 lg:w-1/2">
                <div className="wow slideInRight mt-4 w-full sm:mt-4 sm:w-2/3 md:mt-0 md:w-full">
                  <img src={smartBike} alt="factory"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*endregion*/}

        {/* region CONTENT FOUR */}
        <div className="py-28 flex w-full justify-end" ref={content4Ref}>
          <div className="relative w-[100%] lg:w-3/4">
            <div className="flex flex-col gap-8 md:gap-0 lg:flex-row">
              <div className="flex w-full sm:justify-center md:w-1/2 flex justify-center">

                <div className="flex w-full flex-col  sm:w-[60%]">
                  <div className="wow slideInUp">
                    <Carousel
                      responsive={responsive}
                      draggable={true}
                      containerClass="carousel-container"
                      removeArrowOnDeviceType={["tablet", "mobile"]}
                      className="h-[100%]"
                      autoPlay={content2Visible}
                      autoPlaySpeed={2000}
                      minimumTouchDrag={30}
                      rewind={true}
                    >
                      {apps.map((image, index) => {
                        return (
                          <div
                            className="flex h-full items-center justify-center"
                            key={index}
                          >
                            <img
                              src={image.src}
                              alt={image.alt}
                            />
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                </div>
              </div>

              <div className="wow slideInRight w-full lg:w-1/2 flex items-center">
                <p
                  className="text-sm text-white sm:text-lg md:text-sm xl:text-xl"
                  dangerouslySetInnerHTML={{
                    __html : t('pages.oem.contents.four')
                  }}
                />
              </div>


            </div>
          </div>
        </div>
        {/* endregion */}

        {/* region CONTENT FIVE */}
        <div className="py-28 flex w-full justify-end" ref={content5Ref}>
          <div className="relative w-[100%] lg:w-3/4">
            <div className="flex flex-col gap-8 md:gap-0 lg:flex-row">
              <div className="wow slideInRight w-full lg:w-1/2 flex flex-col justify-center">
                <p className="text-sm text-white sm:text-lg md:text-sm xl:text-xl"
                  dangerouslySetInnerHTML={{
                     __html : t('pages.oem.contents.five')
                   }}
                />
                <div className="flex justify-center pt-12">
                  <button className="w-[15rem] rounded-full bg-primary py-4 px-10 font-bold uppercase text-black ring-inset hover:bg-black hover:text-white hover:ring-2 hover:ring-primary"
                          onClick={() => {
                            setSelectedSection(6);
                            scrollSmoothHandler(formsRef);
                          }}>
                    {t("pages.oem.call")}
                  </button>
                </div>
              </div>

              <div className="w-full sm:flex sm:justify-center md:w-2/3 lg:w-1/2">
                <div className="wow slideInRight mt-4 w-full sm:mt-4 sm:w-2/3 md:mt-0 md:w-full">

                  <img src={factory} alt="factory"/>
                </div>
              </div>
            </div>
          </div>

          {/*<div className="flex w-full flex-col items-center justify-center gap-8 lg:absolute lg:right-0 lg:w-3/4 lg:flex-row">
            <div className="wow slideInRight w-full lg:w-1/2">
              <p
                className="text-sm text-white lg:text-lg"
                dangerouslySetInnerHTML={{
                  __html : t('pages.oem.contents.five')
                }}
              />
              <div className="flex justify-center pt-12">
                <button className="w-[15rem] rounded-full bg-primary py-4 px-10 font-bold uppercase text-black ring-inset hover:bg-black hover:text-white hover:ring-2 hover:ring-primary">
                  Schedule a call
                </button>
              </div>
            </div>
            <div className="wow slideInRight w-full lg:w-1/2">
              <img src={factory} alt="factory"/>
            </div>
          </div>*/}
        </div>
        {/*endregion*/}

        <div ref={formsRef}>
          <Forms customerType="oem" message="pages.oem.contactForm"/>
        </div>
      </div>
      <div className="w-full flex justify-center mb-20">
        <div className=" w-4/5 lg:w-2/5">
          <Faq data={data} styles={styles} config={config} />
        </div>
      </div>
      <Footer background/>
    </div>
  );
};

export default OEM;
