import React, { useEffect, useState } from "react";
import bg_1 from "../_images/backgrounds/bg-1.png";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const navigation = [
  { name: "Fleet", href: "/fleet" },
  { name: "OEM", href: "/oem" },
  { name: "Individual", href: "/individual" },
  { name: "Mentions légales", href: "/legal_mentions" },
  { name: "Espace presse", href: "/press" },
  { name: "CGU", href: "/cgu" },
  { name: "FAQ", href: "/faq" },
];

const MenuPage = () => {
  const [selectedPage, setSelectedPage] = useState(-1);
  const { t } = useTranslation();

  useEffect(() => {
    const loc = window.location.pathname;

    if (loc === "/individual") setSelectedPage(0);
    if (loc === "/fleet") setSelectedPage(1);
    if (loc === "/oem") setSelectedPage(2);
    if (loc === "/faq") setSelectedPage(3);
    if (loc === "/report_bug") setSelectedPage(4);
  }, [window.location.pathname]);

  return (
    <div
      style={{
        backgroundImage: `url("${bg_1}")`,
        height: "100vh",
        width: "100%",
        objectFit: "cover",
        backgroundPosition: "center center",
        position: "fixed",
        zIndex: 1001,
      }}
      className="slow_entrance"
    >
      <div className="flex h-screen w-screen flex-col justify-between">
        <div className="flex h-full flex-col justify-center gap-6 sm:gap-10 lg:flex-row">
          <div className="flex w-full items-center justify-center font-light text-white lg:w-1/2">
            <div className="flex flex-col items-center space-y-2 sm:space-y-4 md:items-start lg:space-y-8">
              <div
                className={classNames(
                  selectedPage === 0
                    ? "text-primary font-medium"
                    : "text-white hover:text-primary",
                  "flex transform cursor-pointer text-lg transition duration-500 ease-in-out hover:translate-x-6 sm:text-3xl"
                )}
                onClick={() => {
                  setSelectedPage(0);
                }}
              >
                <a href="/individual">{t("pages.menu.individual")}</a>
              </div>
              <div
                className={classNames(
                  selectedPage === 1
                    ? "text-primary font-medium"
                    : "text-white hover:text-primary",
                  "flex transform cursor-pointer text-lg transition duration-500 ease-in-out hover:translate-x-6 sm:text-3xl"
                )}
                onClick={() => {
                  setSelectedPage(1);
                }}
              >
                <a href="/fleet">{t("pages.menu.fleet")}</a>
              </div>
              <div
                className={classNames(
                  selectedPage === 2
                    ? "text-primary font-medium"
                    : "text-white hover:text-primary",
                  "flex transform cursor-pointer text-lg transition duration-500 ease-in-out hover:translate-x-6 sm:text-3xl"
                )}
                onClick={() => {
                  setSelectedPage(2);
                }}
              >
                <a href="/oem">{t("pages.menu.oem")}</a>
              </div>
              <div
                className={classNames(
                  selectedPage === 3
                    ? "text-primary font-medium"
                    : "text-white hover:text-primary",
                  "flex transform cursor-pointer text-lg transition duration-500 ease-in-out hover:translate-x-6 sm:text-3xl"
                )}
                onClick={() => {
                  setSelectedPage(3);
                }}
              >
                <a href="/faq">{t("pages.menu.faq")}</a>
              </div>

              <a href="https://store.trackap.com">
                <div
                  className="flex transform cursor-pointer text-lg font-bold text-primary transition duration-500 ease-in-out hover:translate-x-6 sm:text-3xl"
                  onClick={() => {
                    setSelectedPage(4);
                  }}
                >
                  {t("pages.menu.store")}
                </div>
              </a>
            </div>
          </div>

          <div className="flex w-full items-center justify-center text-xl font-light text-white">
            <div className="flex flex-col space-y-2 text-center">
              <div className="flex justify-center text-lg font-bold md:text-2xl">
                {t("pages.menu.contact")}
              </div>
              <h2 className="text-base md:text-xl">Trackap SAS</h2>
              <h2 className="transform cursor-pointer text-base transition duration-500 ease-in-out hover:translate-x-6 hover:text-primary sm:text-xl">
                <a
                  href="https://goo.gl/maps/owFQuGiYMnzCZRJc9"
                  target="_blank"
                  rel="noreferrer"
                >
                  165 avenue de Bretagne, 59000 Lille
                </a>
              </h2>
              <h2 className="transform cursor-pointer text-base transition duration-500 ease-in-out hover:translate-x-6 hover:text-primary sm:text-xl">
                <a href="mailto:contact@trackap.com">contact@trackap.com</a>
              </h2>
              <h2 className="transform cursor-pointer text-base transition duration-500 ease-in-out hover:translate-x-6 hover:text-primary sm:text-xl">
                <a href="tel:+33765682659">(+33) 7 65 68 26 59</a>
              </h2>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default MenuPage;
