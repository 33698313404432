import React, { useState, useRef, useEffect } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import Faq from 'react-faq-component';

// Helpers
import useIntersection from "../_hooks/useIntersection";

// Images
import bicycleLock from "../_images/individual/indiv1.png";
import technician from "../_images/individual/indiv2.png";
import PLAYSTORE from "../assets/img/stores/playstore.png";
import APPSTORE from "../assets/img/stores/appstore.png";

// Carousel
import { responsive } from "../_helpers/responsiveCarousel";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

//Images fr
import Bike from "../_images/individual/FR/Bike.webp";
import Control from "../_images/individual/FR/Control_center.webp";
import Garage from "../_images/individual/FR/Garage.webp";
import Geofence from "../_images/individual/FR/Geofence.webp";
import Home from "../_images/individual/FR/Home.webp";
import Theft from "../_images/individual/FR/Theft.webp";
import Stats from "../_images/individual/FR/Stats.webp";
import Navigation from "../_images/individual/FR/Navigation.webp";
import Universe from "../_images/individual/FR/Universe.webp";
import Trip from "../_images/individual/FR/Trip.webp";

// Images motors
import bafang from "../_images/individual/motors/bafang.png";
import bosch from "../_images/individual/motors/bosch.png";
import panasonic from "../_images/individual/motors/panasonic.png";
import shimano from "../_images/individual/motors/shimano.png";
import yamaha from "../_images/individual/motors/yamaha.png";

// Components
import Forms from "../components/ContactForm";
import Footer from "../components/Footer";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const apps = [
  { src: Bike, alt: "Bike" },
  { src: Control, alt: "Control" },
  { src: Garage, alt: "Garage" },
  { src: Geofence, alt: "Geofence" },
  { src: Home, alt: "Home" },
  { src: Theft, alt: "Theft" },
  { src: Stats, alt: "Stats" },
  { src: Navigation, alt: "Navigation" },
  { src: Universe, alt: "Universe" },
  { src: Trip, alt: "Trip" }
];

const motors = [
  { src: bafang, alt: "Bafang" },
  { src: bosch, alt: "Bosch" },
  { src: panasonic, alt: "Panasonic" },
  { src: shimano, alt: "Shimano" },
  { src: yamaha, alt: "Yamaha" },
  { src: bafang, alt: "Bafang-dup" },
  { src: bosch, alt: "Bosch-dup" },
  { src: panasonic, alt: "Panasonic-dup" },
  { src: shimano, alt: "Shimano-dup" },
  { src: yamaha, alt: "Yamaha-dup" },
];

const Individual = () => {
  const { t } = useTranslation();

  const [selectedSection, setSelectedSection] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);

  //region  INTERSECTION OBSERVER
  const content1Ref = useRef();
  const content2Ref = useRef();
  const content3Ref = useRef();
  const formsRef = useRef();

  const content1Visible = useIntersection(content1Ref, "0%");
  const content2Visible = useIntersection(content2Ref, "-40%");
  const content3Visible = useIntersection(content3Ref, "-40%");
  const formsVisible = useIntersection(formsRef, "-40%");

  const sections = [
    { text: "pages.individual.menu.one", ref: content1Ref },
    { text: "pages.individual.menu.two", ref: content2Ref },
    { text: "pages.individual.menu.three", ref: content3Ref },
    { text: "general.contactUs", ref: formsRef },
  ];

  // region SEO bullshit
  // Needed for the FAQPage schema to work and be recognized by bots
  const structuredData = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": t("pages.individual.head.q1"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("pages.individual.head.a1")
        }
      }, {
      "@type": "Question",
      "name": t("pages.individual.head.q2"),
      "acceptedAnswer": {
        "@type": "Answer",
        "text": t("pages.individual.head.a2")
      }}
    ]
  }

  const faqTitleStyle = {
    fontSize : "1.25rem",
    fontFamily : "Colfax-Medium"
  }

  const data = {
    title: t('pages.individual.faq.title'),
    rows: [
        {
          title: <h2 style={faqTitleStyle}>{t('pages.individual.faq.q1')}</h2>,
          content: t('pages.individual.faq.a1'),
        }, {
          title: <h2 style={faqTitleStyle}>{t('pages.individual.faq.q2')}</h2>,
          content: t('pages.individual.faq.a2'),
        }
    ],
  };

  const styles = {
      bgColor: 'black',
      titleTextColor: "white",
      rowTitleColor: "white",
      rowContentColor: 'white',
      arrowColor: "#cdff00",
  };

  const config = {
      arrowIcon: "x",
  };
  // endregion

  useEffect(() => {
    if (content1Visible) setSelectedSection(0);
    if (content2Visible) setSelectedSection(1);
    if (content3Visible) setSelectedSection(2);
    if (formsVisible) setSelectedSection(3);
  }, [content1Visible, content2Visible, content3Visible, formsVisible]);

  const scrollSmoothHandler = (ref) => {
    const element = ref.current;
    const offset = 80;
    const elementRect = element.getBoundingClientRect().top;
    const offsetPosition = elementRect - offset;
    console.log(offsetPosition);

    ref.current.scrollIntoView({ behavior: "smooth"});
  };
  //endregion

  return (
    <div className="relative w-screen bg-black" ref={content1Ref}>
      <Helmet>
        <title>{t('pages.individual.head.htmTitle')}</title>
        <link rel="canonical" href={t('pages.individual.head.canonical')}/>
        <meta name="description" content={t('pages.individual.head.desc')} />
      </Helmet>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(structuredData),
        }}
      />

      {/* region MENU  */}
      <div className="fixed z-20 flex hidden h-screen w-1/4 text-2xl lg:block">
        <div className="absolute top-1/2 left-1/2 flex -translate-y-1/2 -translate-x-1/2 flex-col space-y-8 font-semibold">
          {sections.map((section, index) => (
            <div
              key={index}
              className={classNames(
                selectedSection === index ? "text-primary font-medium" : "text-white",
                "transform cursor-pointer transition duration-500 ease-in-out hover:translate-x-6 hover:text-primary lg:text-lg xl:text-xl"
              )}
              onClick={() => {
                setSelectedSection(index);
                scrollSmoothHandler(section.ref);
              }}
            >
              {t(section.text)}
            </div>
          ))}
        </div>
      </div>
      {/* endregion */}

      <div className="flex w-full flex-col p-8 md:p-20">
        <h1 className="wow slideInDown pt-20 text-center text-2xl font-bold uppercase text-white md:text-6xl md:leading-[5rem] lg:pt-8">
          {t("pages.individual.title")}
        </h1>

        {/*region CONTENT ONE */}
        <div className="py-28 flex w-full justify-end">
          <div className="relative w-[100%] lg:w-3/4">
            <div className="flex flex-col lg:flex-row">
              <div className="wow slideInLeft md:slideInRight flex w-full items-center">
                <div className="lg:pr-8">
                  <p
                    className="text-sm text-white sm:text-lg md:text-sm xl:text-xl"
                    dangerouslySetInnerHTML={{
                      __html: t("pages.individual.contents.one"),
                    }}
                  />
                </div>
              </div>

              <div className="w-full sm:flex sm:justify-center md:w-2/3 lg:w-1/2">
                <div className="wow slideInRight mt-4 w-full sm:mt-4 sm:w-2/3 md:mt-0 md:w-full">
                  <img src={bicycleLock} alt={t("pages.individual.bikeAlt")} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*endregion*/}

        {/*region CONTENT TWO*/}
        <div className="py-28 flex w-full justify-end" ref={content2Ref}>
          <div className="relative w-[100%] lg:w-3/4">
            <div className="flex flex-col gap-8 md:gap-0 lg:flex-row">
              <div className="flex w-full sm:justify-center md:w-1/2">

                <div className="flex w-full flex-col sm:w-[60%]">
                  <div className="wow slideInUp">
                    <Carousel
                      responsive={responsive}
                      draggable={true}
                      containerClass="carousel-container"
                      removeArrowOnDeviceType={["tablet", "mobile"]}
                      className="h-[100%]"
                      afterChange={(_previousSlide, { currentSlide }) => {
                        setCurrentSlide(currentSlide);
                      }}
                      autoPlay={content2Visible}
                      autoPlaySpeed={3000}
                      minimumTouchDrag={30}
                      rewind={true}
                    >
                      {apps.map((image, index) => {
                        return (
                          <div
                            className="flex h-full items-center justify-center"
                            key={index}
                          >
                            <img
                              src={image.src}
                              alt={image.alt}
                              className="h-full"
                            />
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                </div>
              </div>

              <div className="wow slideInRight flex w-full flex-col items-center justify-center lg:w-1/2">
                <p
                  className="text-sm text-white sm:text-lg md:text-sm xl:text-xl"
                  dangerouslySetInnerHTML={{
                    __html: t("pages.individual.contents.two"),
                  }}
                />

                <div className="flex md:justify-center lg:justify-start">
                  <div className="mt-8 flex grid grid-cols-2 justify-center">
                    <a
                      className="xl:mx-12"
                      href="https://apps.apple.com/us/app/trackap/id1540068496?ign-itsct=apps_box_badge&ign-itscg=30200"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={APPSTORE} alt="Appstore" />
                    </a>
                    <a
                      className="xl:mx-12"
                      href="https://play.google.com/store/apps/details?id=com.trackap.app&gl=FR"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={PLAYSTORE} alt="PlayStore" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*endregion*/}

        {/*region CONTENT THREE */}
        <div className="py-28 flex w-full justify-end" ref={content3Ref}>
          <div className="relative w-[100%] lg:w-3/4">
            <div className="flex flex-col gap-8">
              <div className="flex flex-col md:flex-row">
                <div className="wow slideInLeft md:slideInRight flex w-full items-center">
                  <div>
                    <p
                      className="text-sm text-white sm:text-lg md:text-sm xl:text-xl"
                      dangerouslySetInnerHTML={{
                        __html: t("pages.individual.contents.three"),
                      }}
                    />
                    <div className="mt-8 flex justify-center">
                      <a href="https://store.trackap.com/collections/all" target="_blank">
                        <button className="w-[15rem] rounded-full bg-primary py-4 px-10 font-bold uppercase text-black ring-inset hover:bg-black hover:text-white hover:ring-2 hover:ring-primary">
                          {t("general.actions.buy")}
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="w-full sm:flex sm:justify-center md:w-2/3 lg:w-1/2">
                  <div className="wow slideInRight mt-4 w-full sm:mt-4 sm:w-2/3 md:mt-0 md:w-full">
                    <img src={technician} alt="technician" />
                  </div>
                </div>
              </div>

              <div className="carousel-motors wow fadeIn w-full">
                <div className="carousel-track flex gap-12">
                  {motors.map((motor, index) => (
                    <div className="image-carousel" key={index}>
                      <img
                        src={motor.src}
                        alt={motor.alt}
                        className="object-contain"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*endregion*/}

        <div ref={formsRef}>
          <Forms
            customerType="individual"
            message="pages.individual.contactForm"
          />
        </div>
      </div>
      <div className="w-full flex justify-center mb-20">
        <div className=" w-4/5 lg:w-2/5">
          <Faq data={data} styles={styles} config={config} />
        </div>
      </div>
      <Footer background />
    </div>
  );
};

export default Individual;
