import React from "react";
import { useTranslation } from "react-i18next";

import Footer from "../../components/Footer";

function LegalMentions() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="w-full flex flex-col bg-black text-white px-6 md:px-32 lg:px-60 py-20">
        <h1 className="text-3xl pt-8 text-center">
          {t("pages.legalMentions.legalMentions")}
        </h1>
        <p className="mt-10">{t("pages.legalMentions.descriptionTrackap")}</p>
        <p className="mt-10">{t("pages.legalMentions.director")}</p>
        <p className="mt-10">{t("pages.legalMentions.coords")}</p>
        <p className="mt-10">{t("pages.legalMentions.privacyCoords")}</p>
        <p className="mt-10">{t("pages.legalMentions.realisation")}</p>
        <p className="mt-10">{t("pages.legalMentions.host.host")}</p>
        <p>{t("pages.legalMentions.host.desc1")}</p>
        <p>{t("pages.legalMentions.host.desc2")}</p>
        <p>{t("pages.legalMentions.host.desc3")}</p>
        <p>{t("pages.legalMentions.host.desc4")}</p>
        <a href="https://www.ionos.fr/" target="_blank" rel="noreferrer">https://www.ionos.fr/</a>
      </div>
      <Footer background />
    </div>
  );
}

export default LegalMentions;