import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

import EN from "../locales/en.json";
import FR from "../locales/fr.json";
import CS from "../locales/cs.json";
import DE from "../locales/de.json";
import NL from "../locales/nl.json";
import IT from "../locales/it.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: EN,
        image: "us",
      },
      fr: {
        translation: FR,
        image: "fr",
      },
      it: {
        translation: IT,
        image: "it",
      },
      cs: {
        translation: CS,
        image: "cz",
        beta: true,
      },
      de: {
        translation: DE,
        image: "de",
        beta: true,
      },
      nl: {
        translation: NL,
        image: "nl",
        beta: true,
      },
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
