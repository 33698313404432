import React from "react";
import { useTranslation } from "react-i18next";

import VOZER from '../../assets/img/presse/vozer.jpg'
import FRANCEINTER from '../../assets/img/presse/f-inter.png'
import LVDN from '../../assets/img/presse/lvdn.jpg'
import TRIBUNE from '../../assets/img/presse/tribune.jpg'
import GLTV from '../../assets/img/presse/gltv.jpg'
import ACTUFR from '../../assets/img/presse/actufr.png'
import FRENCHWEB from '../../assets/img/presse/fweb.png'
import EPI from '../../assets/img/presse/epitech.png'
import VELOTECH from '../../assets/img/presse/velotech.png'
import FILE from '../../assets/KitMediaTrackAp.rar'

import Footer from "../../components/Footer";

const articles = [
  {
    name: 'Vozer',
    img: VOZER,
    href: 'https://vozer.fr/2019/06/28/trackap-lobjet-connecte-qui-geolocalise-votre-velo-vole/'
  },
  {
    name: 'France Inter',
    img: FRANCEINTER,
    href: 'https://www.lavoixdunord.fr/595067/article/2019-06-07/trackap-invente-les-traqueurs-tranquilles-de-voleurs-de-velos#_=_&VP=0'
  },
  {
    name: 'La Voix du Nord',
    img: LVDN,
    href: 'https://vozer.fr/2019/06/28/trackap-lobjet-connecte-qui-geolocalise-votre-velo-vole/'
  },
  {
    name: 'La Tribune',
    img: TRIBUNE,
    href: 'https://www.latribune.fr/regions/hauts-de-france/trackap-met-au-point-un-traqueur-de-velo-longue-duree-821723.html'
  },
  {
    name: 'Grand Lille TV',
    img: GLTV,
    href: 'https://www.grandlille.tv/trackap-une-application-pour-retrouver-son-velo-vole/'
  },
  {
    name: 'Actu.fr',
    img: ACTUFR,
    href: 'https://actu.fr/hauts-de-france/lille_59350/lille-lomme-ont-invente-traqueur-lutter-contre-vols-velos_28176569.html'
  },
  {
    name: 'Epitech',
    img: EPI,
    href: 'https://www.epitech.eu/fr/actualites-evenements/des-bancs-de-lecole-a-lincubation-a-euratechnologies/'
  },
  {
    name: 'FrenchWeb',
    img: FRENCHWEB,
    href: 'https://www.frenchweb.fr/fw-radar-iot-trackap-veut-securiser-les-flottes-de-velos-en-europe/382249'
  },
  {
    name: 'VeloTech',
    img: VELOTECH,
    href: 'https://www.velotech.fr/traceur-trackap-la-protection-efficace-de-votre-velo-contre-le-vol/'
  }
]

const Press = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="w-full flex flex-col bg-black text-white px-6 md:px-32 lg:px-60 py-10">
        <h1 className="text-center mt-32 font-bold text-2xl md:text-5xl lg:text-6xl">{t("pages.press.press")}</h1>
        <p className="text-sm lg:text-lg leading-6 mt-6">{t("pages.press.description")}</p>
        <h1 className="text-center mt-16 font-bold text-xl md:text-2xl">{t("pages.press.kit")}</h1>
        <div className="flex justify-center mt-6">
          <a href={FILE} download>
            <button className="w-[15rem] rounded-full bg-primary py-4 px-10 font-bold uppercase text-black ring-inset hover:bg-black hover:text-white hover:ring-2 hover:ring-primary">
              {t("pages.press.download")}
            </button>
          </a>
        </div>
        <div className='pt-20'>
          {articles.map((item, index) => {
            return index % 2 === 0 ?
              <div className="flex flex-row justify-center items-center bg-lightGrey w-full h-40 md:h-52" key={index}>
                <div className="w-40 h-40 flex items-center">
                  <img src={item.img} alt={item.name} />
                </div>
                <a href={item.href} target="_blank" rel="noreferrer" className="px-16 text-sm md:text-lg">{t("pages.press.link")}</a>
              </div> :
              <div className="flex flex-row justify-center items-center w-full h-52" key={index}>
                <a href={item.href} target="_blank" rel="noreferrer" className="px-16 text-sm md:text-lg">{t("pages.press.link")}</a>
                <div className="w-40 h-40 flex items-center">
                  <img src={item.img} alt={item.name} />
                </div>
              </div>
          }
          )}
        </div>
      </div>
      <Footer background />
    </div>
  );
}

export default Press;