import React from "react";

import { useTranslation } from "react-i18next";

import Footer from "../../components/Footer";

const CGU = () => {
  const { t } = useTranslation();

  return (
    <div className="relative w-screen bg-black">
      <div className="w-full flex flex-col text-white px-6 md:px-32 lg:px-60 py-20">
        <h1 className="text-3xl text-center pt-8">{t("pages.CGU.cgu")}</h1>

        <p className="mt-10">{t("pages.CGU.updated")}</p>

        <h2 className="mt-10 text-xl font-bold">{t("pages.CGU.title.title1")}</h2>
        <p className="mt-5">{t("pages.CGU.object")}</p>

        <h2 className="mt-10 text-xl font-bold">{t("pages.CGU.title.title2")}</h2>
        <p className="mt-5">{t("pages.CGU.defs.contentDef")}</p>
        <p className="mt-5">{t("pages.CGU.defs.userDef")}</p>
        <p className="mt-5">{t("pages.CGU.defs.siteDef")}</p>

        <h2 className="mt-10 text-xl font-bold">{t("pages.CGU.title.title3")}</h2>
        <p className="mt-5">{t("pages.CGU.access")}</p>

        <h2 className="mt-10 text-xl font-bold">{t("pages.CGU.title.title4")}</h2>
        <p className="mt-5">{t("pages.CGU.datas.data1")}</p>
        <p className="mt-5">{t("pages.CGU.datas.data2")}</p>
        <p className="mt-5">{t("pages.CGU.datas.data3")}</p>
        <p className="mt-5">{t("pages.CGU.datas.data4")}</p>
        <p className="mt-5">
          {t("pages.CGU.datas.data5")}
          <a href='/privacy_policy'>
            <span className="text-indigo-600">
              {t("pages.CGU.datas.data6")}
            </span>
          </a>
        </p>

        <h2 className="mt-10 text-xl font-bold">{t("pages.CGU.title.title5")}</h2>
        <p className="mt-5">{t("pages.CGU.prop")}</p>

        <h2 className="mt-10 text-xl font-bold">{t("pages.CGU.title.title6")}</h2>
        <p className="mt-5">{t("pages.CGU.responsibilities")}</p>

      </div>
      <Footer background />
    </div>
  );
}

export default CGU;