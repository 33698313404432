import React from "react";
import { useTranslation } from "react-i18next";

function NotFound() {
  const { t } = useTranslation();

  return (
    <div className="bg-black">
      <div className="background-404 flex h-screen w-full flex-col items-center justify-center space-y-9 bg-no-repeat px-6  text-white md:px-16">
        <div className="md:text-title text-7xl">404</div>
        <div className="text-center text-lg md:text-2xl">
          {t("pages.404.text")}
        </div>
        <div className="mt-7">
          <a href="/">
            <button className="w-[15rem] rounded-full bg-primary py-4 px-10 font-bold uppercase text-black ring-inset hover:bg-black hover:text-white hover:ring-2 hover:ring-primary">
              {t("pages.404.back")}
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
