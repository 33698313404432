import React, {
  useEffect,
  useState
} from 'react';
import Swal from 'sweetalert2';
import {useTranslation} from 'react-i18next';
import axios from 'axios';
import {POST_MESSAGE} from '../_constants/constants';

function ContactForm({customerType, message}) {
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');
  const [phone, setPhone] = useState('');
  const {t} = useTranslation();

  function resetForm() {
    setEmail('');
    setMsg('');
    setPhone('');
  }

  function writeMessage() {
    let dataToSend = {
      email : email,
      phone : phone,
      pro : customerType,
      message : msg
    };

    axios.post(POST_MESSAGE, dataToSend).then((res) => {
      if (res.status === 201){
        Swal.fire({
          icon : 'success',
          title : t("pages.contactForm.thanks"),
          text : t("pages.contactForm.comeBack"),
        });
        resetForm();
      }
    }).catch(() => {
      Swal.fire({
        icon : 'error',
        title: t("pages.contactForm.error"),
      })
    });
  }

  const handleSubmit = () => {
    if (!email || email === "" || !msg || msg === "" || !phone || phone === ""){
      Swal.fire({
        title: t("pages.contactForm.allFields"),
        icon: 'warning',
      });
      return;
    }

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const emailValid = re.test(email)
    if (emailValid === false){
      Swal.fire({
        title: t("pages.contactForm.validEmail"),
        icon: 'warning',
      });
      return;
    }
    writeMessage();
  };

  useEffect(() => {
    setMsg(t(message));
  }, []);

  return (
    <div className="flex min-h-screen w-full items-center pt-72 lg:relative lg:pt-0">
      <div className="mt-12 flex w-full justify-center lg:absolute lg:right-0 lg:w-3/4">
        <div className="w-full lg:w-2/3">
          <h2 className="text-center text-2xl text-white lg:text-5xl font-medium">
            {t('pages.contactForm.interested')}
          </h2>

          <div className="flex w-full flex-col justify-between gap-10 pt-8 lg:flex-row">
            <div className="w-full lg:w-1/2">
              <label htmlFor="email" className="block text-sm text-white">
                {t('pages.contactForm.email')}
              </label>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                autoComplete="email"
                required
                className="w-full rounded-lg border-2 border-white bg-black py-3 px-4 text-white placeholder-white focus:outline-none"
              />
            </div>

            <div className="w-full lg:w-1/2">
              <label htmlFor="phone" className="block text-sm text-white">
                {t('pages.contactForm.phone')}
              </label>
              <input
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                id="phone"
                name="phone"
                type="phone"
                autoComplete="phone"
                className="w-full rounded-lg border-2 border-white bg-black px-5 py-3 text-white placeholder-white focus:outline-none"
              />
            </div>
          </div>
          <div className="pt-8">
            <label
              htmlFor="message"
              className="block text-sm font-medium text-white"
            >
              {t('pages.contactForm.message')}
            </label>
            <textarea
              value={msg}
              onChange={(e) => setMsg(e.target.value)}
              id="message"
              name="message"
              rows={10}
              className="block w-full rounded-lg border-2 border-white bg-black py-3 px-4 text-white shadow-sm focus:outline-none"
            />
            <div className="mt-8 flex justify-center">
              <button className="w-[15rem] rounded-full bg-primary py-4 px-10 font-bold uppercase text-black ring-inset hover:bg-black hover:text-white hover:ring-2 hover:ring-primary"
                      onClick={(e) => handleSubmit(e)}
              >
                {t('general.actions.send')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
