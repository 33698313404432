import React, {useState, useEffect, useRef} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import Faq from 'react-faq-component';

// Helpers
import useIntersection from '../_hooks/useIntersection';

// Images
import bicycles from '../_images/B2B/fleet1.png';
import courier from '../_images/B2B/fleet2.png';

// Carousel B2B
import details from '../_images/B2B/CarouselB2B/B2B_details.png';
import geofencing from '../_images/B2B/CarouselB2B/B2B_geofencing.png';
import maintenance from '../_images/B2B/CarouselB2B/B2B_maintenace.png';
import map from '../_images/B2B/CarouselB2B/B2B_map.png';
import stats from '../_images/B2B/CarouselB2B/B2B_stats.png';
import vehicles from '../_images/B2B/CarouselB2B/B2B_vehicles.png';

//Carousel Fleets
import annecy from '../_images/B2B/CarouselFleets/annecy.png';
import tandem from '../_images/B2B/CarouselFleets/tandem.png';
import toutenvelo from '../_images/B2B/CarouselFleets/toutenvelo.png';
import b2ebike from '../_images/B2B/CarouselFleets/b2ebike.png';
import greenOn from '../_images/oem/brands/greenon.png';
import cityq from '../_images/oem/brands/cityq.png';
import incubethic from '../_images/B2B/CarouselFleets/incubethic.png';

// Carousel
import {responsive} from '../_helpers/responsiveCarousel';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

// Components
import Forms from '../components/ContactForm';
import Footer from '../components/Footer';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// Need to dup images
const fleets = [
  {src : annecy, alt : 'Annecy', href: "http://www.velonecy.com"},
  {src : tandem, alt : 'Tandem', href: "https://www.tandemcompany.fr/"},
  {src : toutenvelo, alt : 'Tout en vélo', href : "https://toutenvelo.fr"},
  {src : b2ebike, alt : 'B2Ebike', href : "https://b2ebike.com/"},
  {src : greenOn, alt : 'GreenOn', href : "https://green-on.fr/"},
  {src : cityq, alt : 'CityQ', href : "https://cityq.biz/"},
  {src : incubethic, alt : 'IncubEthic', href : "https://incubethic.fr/"},

  {src : annecy, alt : 'Annecy-dup', href: "http://www.velonecy.com"},
  {src : tandem, alt : 'Tandem-dup', href: "https://www.tandemcompany.fr/"},
  {src : toutenvelo, alt : 'Tout en vélo-dup', href : "https://toutenvelo.fr"},
  {src : b2ebike, alt : 'B2Ebike-dup', href : "https://b2ebike.com/"},
  {src : greenOn, alt : 'GreenOnDup', href : "https://green-on.fr/"},
  {src : cityq, alt : 'CityQDup', href : "https://cityq.biz/"},
  {src : incubethic, alt : 'IncubEthicDup', href : "https://incubethic.fr/"},
];

const b2b = [
  {src : map, alt : 'Map'},
  {src : vehicles, alt : 'Vehicles'},
  {src : details, alt : 'Details'},
  {src : stats, alt : 'Stats'},
  {src : geofencing, alt : 'Geofencing'},
  {src : maintenance, alt : 'Maintenance'}
];

const Fleet = () => {
  const [selectedSection, setSelectedSection] = useState(0);
  const {t} = useTranslation();

  //region Intersection Observer
  const content1Ref = useRef();
  const content2Ref = useRef();
  const content3Ref = useRef();
  const formsRef = useRef();

  const content1Visible = useIntersection(content1Ref, '0%');
  const content2Visible = useIntersection(content2Ref, '-40%');
  const content3Visible = useIntersection(content3Ref, '-40%');
  const formsVisible = useIntersection(formsRef, '-40%');

    // region SEO bullshit
    // Needed for the FAQPage schema to work and be recognized by bots
    const structuredData = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": t("pages.fleet.head.q1"),
        "acceptedAnswer": {
          "@type": "Answer",
          "text": t("pages.fleet.head.a1")
        }
      }, {
      "@type": "Question",
      "name": t("pages.fleet.head.q2"),
      "acceptedAnswer": {
        "@type": "Answer",
        "text": t("pages.fleet.head.a2")
      }}
    ]
  }

  const faqTitleStyle = {
    fontSize : "1.25rem",
    fontFamily : "Colfax-Medium"
  }

  const data = {
    title: t('pages.fleet.faq.title'),
    rows: [
        {
          title: <h2 style={faqTitleStyle}>{t('pages.fleet.faq.q1')}</h2>,
          content: t('pages.fleet.faq.a1'),
        }, {
          title: <h2 style={faqTitleStyle}>{t('pages.fleet.faq.q2')}</h2>,
          content: t('pages.fleet.faq.a2'),
        }, {
          title: <h2 style={faqTitleStyle}>{t('pages.fleet.faq.q3')}</h2>,
          content: t('pages.fleet.faq.a3'),
        },
    ],
  };

  const styles = {
    bgColor: 'black',
    titleTextColor: "white",
    rowTitleColor: "white",
    rowContentColor: 'white',
    arrowColor: "#cdff00",
  };

  const config = {
    arrowIcon: "x",
  };
  // endregion
  
  const sections = [
    {text : 'pages.fleet.menu.one', ref : content1Ref},
    {text : 'pages.fleet.menu.two', ref : content2Ref},
    {text : 'pages.fleet.menu.three', ref : content3Ref},
    {text : 'pages.fleet.contents.getInTouch', ref : formsRef}
  ];

  useEffect(() => {
    if (content1Visible) setSelectedSection(0);
    if (content2Visible) setSelectedSection(1);
    if (content3Visible){
      content3Ref.current.style.visibility = 'visible';
      content3Ref.current.style.animationName = 'slideInRight';
      setSelectedSection(2);
    }
    if (formsVisible) setSelectedSection(3);
  }, [content1Visible, content2Visible, content3Visible, formsVisible]);


  const scrollSmoothHandler = (ref) => {
    ref.current.scrollIntoView({behavior : 'smooth'});
  };
  //endregion

  return (
    <div className="relative w-screen bg-black" ref={content1Ref}>
      
      {/* SEO OPTIMIZATION */}
      <Helmet>
        <title>{t('pages.fleet.head.htmTitle')}</title>
        <link rel="canonical" href={t('pages.fleet.head.canonical')}/>
        <meta name="description" content={t('pages.fleet.head.desc')} />
      </Helmet>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(structuredData),
        }}
      />

      {/*
        // region MENU  
      */}
      <div className="fixed z-20 flex hidden h-screen w-1/4 text-2xl lg:block">
        <div className="absolute top-1/2 left-1/2 flex -translate-y-1/2 -translate-x-1/2 flex-col space-y-8 font-semibold">
          {sections.map((section, index) => (
            <div
              key={index}
              className={classNames(
                selectedSection === index ? 'text-primary font-medium' : 'text-white',
                'transform cursor-pointer transition duration-500 ease-in-out hover:translate-x-6 hover:text-primary lg:text-lg xl:text-xl'
              )}
              onClick={() => {
                setSelectedSection(index);
                scrollSmoothHandler(section.ref);
              }}
            >
              {t(section.text)}
            </div>
          ))}
        </div>
      </div>
      {/*endregion*/}

      <div className="flex w-full flex-col p-8 md:p-20">
        <h1 className="wow slideInDown pt-20 text-center text-3xl font-bold uppercase text-white md:text-6xl md:leading-[5rem] lg:pt-8">
          {t('pages.fleet.title')}
        </h1>

        {/* region CONTENT ONE */}
        <div className="py-28 flex w-full justify-end">
          <div className="relative w-[100%] lg:w-3/4">
            <div className="flex flex-col gap-8">

              <div className="flex flex-col lg:flex-row">
                <div className="wow fadeInRight flex w-full items-center">
                  <p
                    className="text-sm text-white sm:text-lg md:text-sm xl:text-xl"
                    dangerouslySetInnerHTML={{
                      __html : t('pages.fleet.contents.one')
                    }}
                  />
                </div>

                <div className="w-full sm:flex sm:justify-center md:w-1/2 lg:w-1/2">
                  <div className="wow slideInRight mt-4 w-full sm:mt-4 sm:w-2/3 md:mt-0 md:w-full">
                    <img src={bicycles} alt="appointment"/>
                  </div>
                </div>
              </div>

              <div className="wow fadeIn">
                <div className="carousel-fleet">
                  <div className="carousel-track-fleet flex gap-12">
                    {fleets.map((item, index) => {
                      return (
                        <div className="image-carousel-fleet" key={index}>
                          <a href={item.href} target="_blank"><img src={item.src} alt={item.alt}/></a>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*endregion*/}

        {/* region CONTENT TWO */}
        <div
          className="py-28 flex w-full justify-end"
          ref={content2Ref}
        >
          <div className="relative w-[100%] lg:w-3/4">
            <div className="flex flex-col xl:flex-row">

              <div className="wow slideInDown w-full lg:w-2/3">
                <Carousel
                  responsive={responsive}
                  draggable={true}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={['tablet', 'mobile']}
                  className="h-[100%]"
                  autoPlay={content2Visible}
                  autoPlaySpeed={2000}
                  minimumTouchDrag={30}
                  rewind={true}
                >
                  {b2b.map((item, index) => (
                    <img src={item.src} alt={item.alt} key={index}/>
                  ))}
                </Carousel>
              </div>

              <div className="wow slideInRight flex w-full items-center">
                <p
                  className="text-sm text-white sm:text-lg md:text-sm xl:text-xl"
                  dangerouslySetInnerHTML={{
                    __html : t('pages.fleet.contents.two')
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/*endregion*/}

        {/*region CONTENT THREE */}
        <div className="py-28 flex w-full justify-end" ref={content3Ref}>
          <div className="relative w-[100%] lg:w-3/4">
            <div className="flex flex-col gap-8 md:gap-0 lg:flex-row">

              <div className="wow slideInRight flex items-center w-full md:w-3/5">
                <div>
                  <p
                    className="text-sm text-white sm:text-lg md:text-sm xl:text-xl"
                    dangerouslySetInnerHTML={{
                      __html : t('pages.fleet.contents.three')
                    }}
                  />
                  <div className="flex justify-center pt-8">
                    <button className="w-[15rem] rounded-full bg-primary py-4 px-10 font-bold uppercase text-black ring-inset hover:bg-black hover:text-white hover:ring-2 hover:ring-primary" onClick={() => {setSelectedSection(3); scrollSmoothHandler(formsRef);}}>
                      {t('pages.fleet.contents.getInTouch')}
                    </button>
                  </div>
                </div>
              </div>

              <div className="w-full sm:flex sm:justify-center md:w-2/3 lg:w-1/2">
                <div className="wow slideInRight mt-4 w-full sm:mt-4 sm:w-2/3 md:mt-0 md:w-full">
                  <img src={courier} alt="courier"/>
                </div>
              </div>

            </div>
          </div>
        </div>
        {/*endregion*/}

        <div ref={formsRef}>
          <Forms customerType="fleet" message="pages.fleet.contactForm"/>
        </div>
      </div>
      <div className="w-full flex justify-center mb-20">
        <div className=" w-4/5 lg:w-2/5">
          <Faq data={data} styles={styles} config={config} />
        </div>
      </div>
      <Footer background/>
    </div>
  );
};

export default Fleet;
