import React from "react";
import { Fragment, useEffect, useRef, useState } from "react";

import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import Footer from "../components/Footer";
import axios from "axios";
import { REPORT_BUG } from "../_constants/constants";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const impactStages = [
  { label: "Bas", value: "Minor", color: "blue" },
  { label: "Moyen", value: "Normal", color: "yellow" },
  { label: "Important", value: "Major", color: "orange" },
  { label: "Critique", value: "Critical", color: "red" },
];

const listPlatforms = [
  { label: "Plateforme B2B", value: "B2B" },
  { label: "Application mobile", value: "B2C" },
  { label: "Site web", value: "Website" },
  { label: "Plateforme OEM", value: "OEM" },
  { label: "Plateforme Connect", value: "Connect" },
  { label: "Factory", value: "Factory" },
];

const BugReport = () => {
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [severity, setSeverity] = useState("");
  const [severityValue, setSeverityValue] = useState("");
  const [device, setDevice] = useState("");
  const [platform, setPlatform] = useState("");
  const [platformValue, setPlatformValue] = useState("");
  const [files, setFiles] = useState([]);

  const {t} = useTranslation();
  
  // get params from url
  const urlParams = new URLSearchParams(window.location.search);
  const emailParam = urlParams.get("email");
  const impactParam = urlParams.get("severity");
  const infosParam = urlParams.get("device");
  const platformParam = urlParams.get("app");
  const userId = urlParams.get("userId");
  const titleParam = urlParams.get("title");
  const descriptionParam = urlParams.get("description");

  const [openMenu, setOpenMenu] = useState(false);
  const refMenu = useRef();

  useEffect(() => {
    if (emailParam) {
      setEmail(emailParam);
    }
    if (impactParam) {
      setSeverity(impactParam);
    }
    if (infosParam) {
      setDevice(infosParam);
    }
    if (platformParam) {
      setPlatform(platformParam);
    }
    if (titleParam) {
      setTitle(titleParam);
    }
    if (descriptionParam) {
      setDescription(descriptionParam);
    }
  }, [
    emailParam,
    impactParam,
    infosParam,
    platformParam,
    userId,
    titleParam,
    descriptionParam,
  ]);

  async function reportBug() {
    const formData = new FormData();

    formData.append("title", title);
    formData.append("description", description);
    formData.append("severity", severityValue);
    formData.append("platform", platformValue);
    formData.append("device", device);
    formData.append("email", email);
    if (userId) formData.append("uid", userId);

    if (files.length > 0) {
      files.map((file) => {
        formData.append("image", file);
      });
    }

    return axios
      .post(REPORT_BUG, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          //TODO : Translate
          Swal.fire({
            title: t("pages.bugreport.bugreported"),
            text: "Merci pour votre contribution",
            icon: "success",
          });
          resetForm();
        }
      })
      .catch((error) => {
        if (title.length < 5) {
          Swal.fire({
            title: t("pages.bugreport.error"),
            text: t("pages.bugreport.titleshort"),
            icon: "warning",
          });
        } else if (description.length < 32) {
          Swal.fire({
            title: t("pages.bugreport.error"),
            text: t("pages.bugreport.descshort"),
            icon: "warning",
          });
        } else {
          Swal.fire({
            title: t("pages.bugreport.error"),
            text: error.response.data.error,
            icon: "error",
          });
        }
        return { success: false, message: error.message };
      });
  }

  function resetForm() {
    setEmail("");
    setDescription("");
    setTitle("");
    setSeverity("");
    setSeverityValue("");
    setDevice("");
    setPlatform("");
    setFiles([]);
  }

  useEffect(() => {
    let value = impactStages.find((stage) => stage.label === severity);
    setSeverityValue(value?.value);
  }, [severity]);
  useEffect(() => {
    let value = listPlatforms.find((_platform) => _platform.label === platform);
    setPlatformValue(value?.value);
  }, [platform]);

  return (
    <div className="min-h-screen w-screen bg-black">

      <div className="flex w-full flex-col p-8 md:p-20">
        <h1 className="pt-8 text-center text-3xl font-bold uppercase text-white md:text-6xl md:leading-[5rem]">
        {t("pages.bugreport.titlepage")}
        </h1>

        <div className="flex flex-col items-center space-y-4 pt-12">
          <div className="flex w-full flex-col lg:w-1/3 xl:w-1/4">
            <label className="text-white">{t("pages.bugreport.title")}</label>
            <input
              minLength={5}
              maxLength={100}
              className="rounded-lg border border-white bg-black px-4 py-2 text-white focus:outline-none"
              placeholder="Affichage des statistiques"
              value={title}
              onChange={(e) => {
                // if ()
                setTitle(e.target.value);
              }}
            />
          </div>

          <div className="flex w-full flex-col lg:w-1/3 xl:w-1/4">
            <label className="text-white">{t("pages.bugreport.application")}</label>

            <Combobox value={platform} onChange={setPlatform}>
              <div className="relative mt-1">
                <div className="w-full cursor-default overflow-hidden rounded-lg border  border-white bg-black">
                  <Combobox.Input
                    className="w-full bg-black px-4 py-2 text-white focus:outline-none"
                    onChange={(e) => setPlatform(e.target.value)}
                    displayValue={platform.label}
                    placeholder="Application mobile"
                  />
                  <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </Combobox.Button>
                </div>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-lg bg-black text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {listPlatforms.map((_platform) => {
                      return (
                        <Combobox.Option
                          key={_platform.value}
                          value={_platform.label}
                        >
                          {({ selected, active }) => (
                            <div
                              className={`flex cursor-pointer items-center justify-between p-2 text-white ${
                                active ? "bg-gray-700" : ""
                              } ${selected ? "bg-gray-700" : ""}`}
                            >
                              <span>{_platform.label}</span>
                              {selected && (
                                <CheckIcon
                                  className="h-5 w-5 text-white"
                                  aria-hidden="true"
                                />
                              )}
                            </div>
                          )}
                        </Combobox.Option>
                      );
                    })}
                  </Combobox.Options>
                </Transition>
              </div>
            </Combobox>
          </div>
          <div className="flex w-full flex-col lg:w-1/3 xl:w-1/4">
            <label className="text-white">{t("pages.bugreport.infos")}</label>
            <input
              className="rounded-lg border border-white bg-black px-4 py-2 text-white focus:outline-none"
              placeholder="Iphone 12 IOS 16.1"
              value={device}
              onChange={(e) => {
                setDevice(e.target.value);
              }}
              type="email"
            />
          </div>

          <div className="flex w-full flex-col lg:w-1/3 xl:w-1/4">
            <label className="text-white">{t("pages.bugreport.email")}</label>
            <input
              className="rounded-lg border border-white bg-black px-4 py-2 text-white focus:outline-none"
              placeholder="example@trackap.com"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>

          <div className="flex w-full flex-col lg:w-1/3 xl:w-1/4">
            <label className="text-white">{t("pages.bugreport.severity")}</label>
            <Combobox value={severity} onChange={setSeverity}>
              <div className="relative mt-1">
                <div className="w-full cursor-default overflow-hidden rounded-lg border  border-white bg-black">
                  <Combobox.Input
                    className="w-full bg-black px-4 py-2 text-white focus:outline-none"
                    onChange={(e) => setSeverity(e.target.value)}
                    displayValue={severity.label}
                    placeholder="Bas"
                  />
                  <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </Combobox.Button>
                </div>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-lg bg-black text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {impactStages.map((impactStage) => {
                      return (
                        <Combobox.Option
                          key={impactStage.value}
                          value={impactStage.label}
                        >
                          {({ selected, active }) => (
                            <div
                              className={`flex cursor-pointer items-center justify-between p-2 text-white ${
                                active ? "bg-gray-700" : ""
                              } ${selected ? "bg-gray-700" : ""}`}
                            >
                              <span>{impactStage.label}</span>
                              {selected && (
                                <CheckIcon
                                  className="h-5 w-5 text-white"
                                  aria-hidden="true"
                                />
                              )}
                            </div>
                          )}
                        </Combobox.Option>
                      );
                    })}
                  </Combobox.Options>
                </Transition>
              </div>
            </Combobox>
          </div>

          <div className="flex w-full flex-col lg:w-1/3 xl:w-1/4">
            <label className="text-white">Description du bug <br/>(Min: 32 car. Max: 1000 car.)</label>
            <textarea
              minLength={32}
              maxLength={1000}
              className="rounded-lg border border-white bg-black px-4 py-2 text-white focus:outline-none"
              placeholder="Description"
              value={description}
              rows={8}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </div>
          <div className="flex w-full flex-col lg:w-1/3 xl:w-1/4">
            <button
              className="rounded-lg border-2 border-black bg-primary px-4 py-2 font-medium text-black hover:bg-primary_dark focus:outline-none"
              onClick={() => {
                document.getElementById("inputFile").click();
              }}
            >
              {t("pages.bugreport.picture")}
            </button>
            <input
              id="inputFile"
              type="file"
              className="hidden"
              onChange={(e) => {
                setFiles([...files, e.target.files[0]]);
              }}
            />
            <div className="flex flex-col pt-2">
              {files.map((_file, index) => {
                return (
                  <div
                    className="flex items-center justify-between"
                    key={index}
                  >
                    <p className="text-white">{_file.name}</p>
                    <button
                      className="rounded-lg border-2 border-black bg-primary px-4 py-2 font-medium text-black hover:bg-primary_dark focus:outline-none"
                      onClick={() => {
                        let newFile = [...files];
                        newFile.splice(index, 1);
                        setFiles(newFile);
                      }}
                    >
                      {t("general.actions.delete")}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="flex w-full flex-col pt-8 lg:w-1/3 xl:w-1/4">
            <button
              className="rounded-lg border-2 border-black bg-primary px-4 py-2 font-medium text-black hover:bg-primary_dark focus:outline-none"
              onClick={() => {
                reportBug();
              }}
            >
              {t("general.actions.send")}
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BugReport;
