import axios from "axios";

export const API_KEY = "508E1FF0-5A58-4AFC-8D64-83C8581EF6C1";

const BASE_URL = "https://api.trackap.com";
// const BASE_URL = "http://192.168.1.133:3050";

export const REPORT_BUG = `${BASE_URL}/reportBug`;
export const POST_MESSAGE = `${BASE_URL}/proMessage`;

export const VIDEOS_INTEGRATION = `${BASE_URL}/dynamicContent/videos-trackap`;

axios.defaults.headers["Authorization"] = API_KEY;
