import React from 'react';

import w_logo from '../_images/logos/white_logo.png';
import {
  Menu,
  Transition
} from '@headlessui/react';
import {
  Fragment,
  useEffect,
  useState
} from 'react';
import {useTranslation} from 'react-i18next';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Navbar = ({setOpen, open, menu, transparent}) => {
  const {i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [languages, setLanguages] = useState([
    {
      src : 'https://cdn.countryflags.com/thumbs/france/flag-800.png',
      lang : 'FR',
      name : 'Français'
    },
    {
      src : 'https://cdn.countryflags.com/thumbs/united-kingdom/flag-800.png',
      lang : 'EN',
      name : 'English'
    },
    /*{
      src : 'https://cdn.countryflags.com/thumbs/czech-republic/flag-800.png',
      lang : 'CS',
      name : 'Čeština'
    },
    {
      src : 'https://cdn.countryflags.com/thumbs/netherlands/flag-800.png',
      lang : 'NL',
      name : 'Nederlands'
    },
    {
      src : 'https://cdn.countryflags.com/thumbs/germany/flag-800.png',
      lang : 'DE',
      name : 'Deutsch'
    },
    {
      src : 'https://cdn.countryflags.com/thumbs/italy/flag-800.png',
      lang : 'IT',
      name : 'Italiano'
    }*/
  ]);

  useEffect(() => {
    if (i18n){
      const currentLanguage =
        i18n && i18n.resolvedLanguage && i18n.store
        ? i18n.store.data[i18n.resolvedLanguage]
        : null;
      if (currentLanguage){
        const foundIndex = languages.findIndex(
          (lang) =>
            lang.lang.toLowerCase() ===
            currentLanguage.translation.locale.toLowerCase()
        );
        if (foundIndex !== -1) onSelect(foundIndex);
      }
    }
  }, [i18n]);

  function onSelect(index) {
    let newLanguages = languages.slice();

    newLanguages[0] = languages[index];
    newLanguages[index] = languages[0];
    setLanguages(newLanguages);
  }

  function changeLng(index) {
    changeLanguage(languages[index].lang.toLowerCase());
    onSelect(index);
  }

  return (
    <div
      className={classNames(
        transparent || window.location.pathname === '/' ? '' : 'bg-black',
        'fixed w-screen py-4'
      )}
      style={{zIndex : 1001}}
    >
      <div className="sm:h10 h-8 w-full md:h-14">
        <a href="/">
          <img
            src={w_logo}
            className="absolute left-1/2 h-8 -translate-x-1/2 cursor-pointer sm:h-10 md:h-14"
            alt="White Logo"
          />
        </a>

        {open && (
          <div className="absolute left-0 sm:right-20 sm:left-auto">
            <Menu as="div" className="relative z-[1002] ml-3">
              <div>
                <Menu.Button className="flex bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                  <span className="sr-only">Open language menu</span>
                  <img className="h-10 w-14" src={languages[0].src} alt=""/>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-left rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none translate-x-2/3 sm:translate-x-0">
                  {languages.map((lang, index) => (
                    <Menu.Item key={lang.name} style={{cursor : 'pointer'}}>
                      {({active}) => (
                        <p
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'block px-4 py-2 text-sm text-gray-700'
                          )}
                          onClick={() => changeLng(index)}
                        >
                          {lang.name}
                        </p>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        )}

        {menu ? (
          <div
            id="menuToggle"
            className="flex -translate-x-1/2 justify-end"
            onClick={() => setOpen(!open)}
          >
            <input type="checkbox"/>
            <span id="span1"></span>
            <span id="span2"></span>
            <span id="span3"></span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Navbar;
