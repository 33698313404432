import React from "react";
import { Helmet } from "react-helmet";
import Faq from "react-faq-component";

// Image imports
import bg_1 from "../../src/_images/backgrounds/bg-1.png";
import bg_2 from "../../src/_images/backgrounds/bg-2.png";
import bg_3 from "../../src/_images/backgrounds/bg-3.png";
import B2B from "../_images/menu/b2b.png";
import individual from "../_images/menu/mockup.png";
import OEM from "../_images/menu/oem.png";

// Components imports
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";

const navigation = [
  { name: "Fleet", href: "/fleet" },
  { name: "OEM", href: "/oem" },
  { name: "Individual", href: "/individual" },
  { name: "Mentions légales", href: "/legal_mentions" },
  { name: "Espace presse", href: "/press" },
  { name: "CGU", href: "/cgu" },
  { name: "FAQ", href: "/faq" },
];

const Home = () => {
  const { t } = useTranslation();

  const getBg = () => {
    const bg = [bg_1, bg_2, bg_3];
    return bg[Math.floor(Math.random() * bg.length)];
  }

  // region SEO bullshit
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
    "@type": "Question",
    "name": t('pages.home.head.faq.q1'),
    "acceptedAnswer": {
      "@type": "Answer",
      "text": t('pages.home.head.faq.q1'),
    }
    },{
      "@type": "Question",
      "name": t('pages.home.head.faq.q1'),
      "acceptedAnswer": {
        "@type": "Answer",
        "text": t('pages.home.head.faq.q1'),
      }
    }]
  }

  const faqTitleStyle = {
    fontSize : "1.25rem",
    fontFamily : "Colfax-Medium"
  }

  const data = {
    title: t('pages.home.faq.title'),
    rows: [
        {
          title: <h2 style={faqTitleStyle}>{t('pages.home.faq.q1')}</h2>,
          content: t('pages.home.faq.a1'),
        }, {
          title: <h2 style={faqTitleStyle}>{t('pages.home.faq.q2')}</h2>,
          content: t('pages.home.faq.a2'),
        }, {
          title: <h2 style={faqTitleStyle}>{t('pages.home.faq.q3')}</h2>,
          content: t('pages.home.faq.a3'),
        },
    ],
  };

const styles = {
    bgColor: 'black',
    titleTextColor: "white",
    rowTitleColor: "white",
    rowContentColor: 'white',
    arrowColor: "#cdff00",
};

const config = {
    arrowIcon: "x",
};
// endregion

  return (
    <div
      style={{
        backgroundImage: `url("${getBg()}")`,
        height: "100vh",
        width: "100%",
        objectFit: "cover",
        backgroundPosition: "center center",
      }}
    >
      {/* SEO OPTIMIZATION ELEMENTS */}
      <Helmet>
        <title>{t('pages.home.head.htmTitle')}</title>
        <link rel="canonical" href={t('pages.home.head.canonical')}/>
      </Helmet>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(structuredData),
        }}
      />


      <div className="flex h-full w-full flex-col justify-center p-8 md:p-20">
        <h1 className="text-3xl md:text-4xl lg:text-7xl font-bold uppercase text-primary md:leading-[5rem]" alt={t('pages.home.bikeAlt')}>
          <span className="text-white text-3xl md:text-4xl lg:text-7xl font-bold uppercase md:leading-[5rem]">{t("pages.home.title.tracker")}</span><br/>
          {t("pages.home.title.next")}<br/>
          {t("pages.home.title.experience")}<br/>
          {t("pages.home.title.oneStop")}<br/>
        </h1>
      </div>

      {/* GREEN SHAPE WITH TEXT */}
      <div className="relative h-[100vh]">
        <div className="shape z-20" />
        <div className="absolute top-0 flex h-full w-full flex-col justify-center bg-black bg-black p-8 text-center text-sm font-medium uppercase tracking-wide text-black sm:text-xl md:px-40 lg:text-3xl xl:text-4xl">
          <h2 className="z-30 font-bold mb-8">{t("pages.home.description.first")}</h2>
          <h2 className="z-30 font-bold">{t("pages.home.description.second")}</h2>
        </div>
      </div>

      {/* MENU WITH BUTTONS AND IMAGES */}
      <div className="flex w-full flex-col items-center justify-center bg-black p-8 lg:p-20">
        <div className="mt-12 text-center text-xl font-bold uppercase text-white md:text-3xl">
          {t("pages.home.menu.text")}
        </div>
        <div className="mt-8 text-white md:text-xl md:w-4/5">
          {t("pages.home.menu.content")}
        </div>
        <div className="mt-4 text-white md:text-xl md:w-4/5">
          {t("pages.home.menu.content2")}
        </div>

        <div className="mt-24 flex w-full flex-col items-center justify-center space-y-24 text-white lg:flex-row lg:space-y-0 lg:space-x-8">
          <div className="wow slideInLeft w-full lg:w-1/3">
            <div className="flex flex-col items-center">
              <a href="/fleet">
                <button className="w-[15rem] rounded-full bg-primary py-4 px-10 font-bold uppercase text-black ring-inset hover:bg-black hover:text-white hover:ring-2 hover:ring-primary">
                  {t("pages.fleet.title")}
                </button>
              </a>
              <img src={B2B} alt="B2B" />
            </div>
          </div>

          <div className="wow slideInUp w-full lg:w-1/3">
            <div className="flex flex-col items-center">
              <a href="/individual">
                <button className="w-[15rem] rounded-full bg-primary py-4 px-10 font-bold uppercase text-black ring-inset hover:bg-black hover:text-white hover:ring-2 hover:ring-primary">
                  {t("pages.individual.title")}
                </button>
              </a>
              <img src={individual} alt="Individual" />
            </div>
          </div>

          <div className="wow slideInRight w-full lg:w-1/3">
            <div className="flex flex-col items-center">
              <a href="/oem">
                <button className="w-[15rem] rounded-full bg-primary py-4 px-10 font-bold uppercase text-black ring-inset hover:bg-black hover:text-white hover:ring-2 hover:ring-primary">
                  {t("pages.oem.title")}
                </button>
              </a>
              <img src={OEM} alt="OEM" />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center mb-20">
        <div className=" w-4/5 lg:w-2/5">
          <Faq data={data} styles={styles} config={config} />
        </div>
      </div>

      <Footer background />
    </div>
  );
};

export default Home;
