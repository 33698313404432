import React, {useRef, useState, useEffect} from "react";
import {useTranslation} from "react-i18next";

import {
  Accordion,
  AccordionItem,
  AccordionPanel,
} from "../../components/Accordion";
import BIKE from "../../assets/img/icons/bike.svg";
import GPS from "../../assets/img/icons/gps.svg";
import PHONE from "../../assets/img/icons/phone.svg";
import GLASSES from "../../assets/img/icons/glasses.svg";
import GUIDE from "../../assets/img/icons/user-guide.svg";

// Manuals images
import BafangM200Img from "../../assets/img/manuals/BafangM200.svg";
import BafangM400Img from "../../assets/img/manuals/BafangM400-420.svg";
import BoschImg from "../../assets/img/manuals/Bosch.png";
import BoschGen4Img from "../../assets/img/manuals/Frame.svg";
import BoschSmartImg from "../../assets/img/manuals/Bosch smart system.svg";
import PanasonicImg from "../../assets/img/manuals/Panasonic.png";
import ShimanoImg from "../../assets/img/manuals/Shimano.png";
import YamahaImg from "../../assets/img/manuals/Yamaha.png";
import XiaomiImg from "../../assets/img/manuals/Xiaomi.png";
import Xt60Img from "../../assets/img/manuals/xt60.svg";
import UniverselImg from "../../assets/img/manuals/Universel.png";

// Icons
import YoutubeLogo from "../../assets/img/icons/YouTubeIcon.svg"
import AppLogo from "../../assets/img/icons/AppIcon.svg"
import IntragrationLogo from "../../assets/img/icons/ManualsIcon.svg"

import faq from "./faq.json";
import {VIDEOS_INTEGRATION} from "../../_constants/constants";
import axios from "axios";

const categories = [
  {
    id: "Tracker",
    content: "pages.faq.categories.tracker",
    icon: GPS,
    current: false,
  },
  {
    id: "Application",
    content: "pages.faq.categories.app",
    icon: PHONE,
    current: false,
  },
  {
    id: "Bike",
    content: "pages.faq.categories.bike",
    icon: BIKE,
    current: false,
  },
  {
    id: "Privacy",
    content: "pages.faq.categories.privacy",
    icon: GLASSES,
    current: false,
  },
  {
    id: "Manuals",
    content: "pages.faq.categories.manual",
    icon: GUIDE,
    current: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// const currentLanguage = localStorage.getItem("i18nextLng");
const manualsLanguages = [
  {
    src: "https://cdn.countryflags.com/thumbs/france/flag-800.png",
    lang: "FR",
    manuals: [
      {key: "shimano", img: ShimanoImg, href: "https://www.youtube.com/watch?v=EZoR3j-hLYU"},
      {key: "bafang", img: BafangM400Img, href: "https://www.youtube.com/watch?v=HpL9Ba0KB58"},
      {key: "bosch3", img: BoschImg, href: "https://www.youtube.com/watch?v=WGQYY9gGs3A"},
      {key: "bosch4", img: BoschGen4Img, href: "https://www.youtube.com/watch?v=pl_R0gz-C7g"},
      {key: "xt60", img: Xt60Img, href: "https://www.youtube.com/watch?v=hSkpX87dlbI"},
      {key: "universel", img: UniverselImg, href: "https://www.youtube.com/watch?v=N2IFSTdYj_A"},
      {key: "xiaomi", img: XiaomiImg, href: "https://www.youtube.com/watch?v=Arp_iqDFE88"},
    ],
  },
  {
    src: "https://cdn.countryflags.com/thumbs/united-kingdom/flag-800.png",
    lang: "EN",
    manuals: [
      {key: "shimano", img: ShimanoImg, href: "https://www.youtube.com/watch?v=EZoR3j-hLYU"},
      {key: "bafang", img: BafangM400Img, href: "https://www.youtube.com/watch?v=HpL9Ba0KB58"},
      {key: "bosch3", img: BoschImg, href: "https://www.youtube.com/watch?v=WGQYY9gGs3A"},
      {key: "bosch4", img: BoschGen4Img, href: "https://www.youtube.com/watch?v=pl_R0gz-C7g"},
      {key: "xt60", img: Xt60Img, href: "https://www.youtube.com/watch?v=hSkpX87dlbI"},
      {key: "universel", img: UniverselImg, href: "https://www.youtube.com/watch?v=N2IFSTdYj_A"},
      {key: "xiaomi", img: XiaomiImg, href: "https://www.youtube.com/watch?v=Arp_iqDFE88"},
    ],
  },
  // {
  //   src: "https://cdn.countryflags.com/thumbs/czech-republic/flag-800.png",
  //   lang: "CS"
  // },
  // {
  //   src: "https://cdn.countryflags.com/thumbs/netherlands/flag-800.png",
  //   lang: "NL"
  // },
  // {
  //   src: "https://cdn.countryflags.com/thumbs/germany/flag-800.png",
  //   lang: "DE"
  // },
  // {
  //   src: "https://cdn.countryflags.com/thumbs/italy/flag-800.png",
  //   lang: "IT"
  // },
];

const Manuals = ({t, i18n, scrollToRef, videos}) => {
  const [selectedLanguage, setSelectedLanguages] = useState(null);
  const [category, setCategory] = useState(null);
  const ImgTitleRef = useRef(null);

  useEffect(() => {
    const language = i18n.language.substring(0, 2).toLocaleUpperCase();
    let manualLanguage = manualsLanguages.find(
      (manuals) => manuals.lang === language
    );
    if (!manualLanguage) manualLanguage = manualsLanguages[0];
    setSelectedLanguages(manualLanguage);
  }, [t, i18n]);

  useEffect(() => scrollToRef(ImgTitleRef), [category, scrollToRef])

  const downloadManual = (href) => {
    if (!href) {
      return;
    }
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("target", "_blank");
    link.setAttribute("ref", "noreferrer");
    if (category === "manuals") {
      link.setAttribute("download", "");
    }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className="w-full md:px-3">
      <div className="grid md:grid-cols-3 w-full gap-4 lg:gap-8 pb-10">
        <div
          className="bg-white w-full flex justify-center items-center py-6 md:py-8 rounded-lg flex-row cursor-pointer"
          onClick={() => category !== "videos" ? setCategory("videos") : setCategory(null)}
        >
          <div className="w-1/2 xl:w-1/2 flex justify-center">
            <img src={YoutubeLogo} alt="logo_youtube" className="h-24 md:h-16 lg:h-20 xl:h-24 object-contain p-1"/>
          </div>
          <p className="w-1/2 xl:w-2/3 flex items-center text-md xl:text-2xl font-medium p-1">
            {t("pages.faq.manual.videos")}
          </p>
        </div>
        <div
          className="bg-white w-full flex justify-center items-center py-6 md:py-8 rounded-lg flex-row cursor-pointer"
          onClick={() => category !== "manuals" ? setCategory("manuals") : setCategory(null)}
        >
          <div className="w-1/2 xl:w-1/2 flex justify-center">
            <img src={IntragrationLogo} alt="logo_integration" className="h-24 md:h-16 lg:h-20 xl:h-24 object-contain p-2"/>
          </div>
          <p className="w-1/2 flex xl:w-2/3 items-center text-md xl:text-2xl font-medium p-1">
            {t("pages.faq.manual.manuals")}
          </p>
        </div>
        <div className="bg-white w-full flex justify-center items-center py-6 md:py-8 rounded-lg flex-row cursor-pointer"
             onClick={() => downloadManual(`https://trackap.com/notices/application-${i18n.resolvedLanguage}.pdf`)}>
          <div className="w-1/2 xl:w-1/2 flex justify-center">
            <img src={AppLogo} alt="logo_manuals" className="h-24 md:h-16 lg:h-20 xl:h-24 object-contain"/>
          </div>
          <p className="w-1/2 xl:w-2/3 flex items-center text-md xl:text-2xl font-medium p-1">
            {t("pages.faq.manual.application")}
          </p>
        </div>
      </div>
      {category === "videos" &&
        <div className="text-white text-4xl uppercase text-center py-6 font-medium" ref={ImgTitleRef}>{t("pages.faq.manual.videoTitle")}</div>
      }
      {category === "manuals" &&
        <div className="text-white text-4xl uppercase text-center py-6 font-medium" ref={ImgTitleRef}>{t("pages.faq.manual.manualTitle")}</div>
      }
      <div className="grid sm:grid-cols-2 md:grid-cols-4 w-full gap-4">
        {
          category !== null && selectedLanguage && selectedLanguage.manuals.map((manual, index) => {
            let video;
            if (videos)
              video = videos.find((v) => v.brand === manual.key);
            else {
              video = "https://trackap.com/404"
            }
            return (
              <div className="bg-white rounded-lg w-full flex justify-center items-center h-24 key={index} cursor-pointer" key={index}>
                <img
                  src={manual.img}
                  alt={`${manual.key}_img`}
                  className="w-full h-20 md:h-16 lg:h-20 xl:h-24 p-4 object-contain"
                  onClick={() => downloadManual(category === "videos" ? video.link : `https://trackap.com/notices/${i18n.resolvedLanguage}/${manual.key}.pdf`)}
                />
              </div>
            )
          })
        }
      </div>
    </div>
  );
}

const FAQAccordion = (props) => {
  const faqs = faq;

  return (
    <div>
      <Accordion>
        {faqs.map((f, index) => {
          if (f.includes(props.categories)) {
            return (
              <React.Fragment key={index}>
                <AccordionItem toggle={index}>{props.t(f)}</AccordionItem>
                <AccordionPanel id={index}>
                  {props.t(f + "Content")}
                </AccordionPanel>
              </React.Fragment>
            );
          } else if (
            props.searchText !== "" &&
            (props
                .t(f + "Content")
                .toUpperCase()
                .match(props.searchText.toUpperCase()) ||
              props.t(f).toUpperCase().match(props.searchText.toUpperCase()))
          ) {
            return (
              <React.Fragment key={index}>
                <AccordionItem toggle={index}>{props.t(f)}</AccordionItem>
                <AccordionPanel id={index}>
                  {props.t(f + "Content")}
                </AccordionPanel>
              </React.Fragment>
            );
          } else {
            return <React.Fragment key={index}></React.Fragment>;
          }
        })}
      </Accordion>
    </div>
  );
};

export const FAQ = () => {
  const {t, i18n} = useTranslation();

  const [selected, setSelected] = useState(null);
  const [searchText, setSearchText] = useState("");

  const [videos, setVideos] = useState(null);

  const questionsRef = useRef();
  const scrollToRef = (ref) => ref.current?.scrollIntoView({behavior: "smooth"});

  useEffect(() => {
    axios.get(VIDEOS_INTEGRATION).then((res) => {
      setVideos(JSON.parse(res.data.message));
    }).catch((err) => {
      console.error("Error when retrieving videos", err);
    });
  }, []);

  return (
    <>
      <div>
        <div className="py-12 px-4 md:px-6 lg:px-20 2xl:container 2xl:mx-auto">
          <div className="flex w-full flex-col items-center justify-center">
            <div className="flex flex-col items-center justify-center space-y-4 pt-20 text-center">
              <h1 className="text-3xl font-semibold uppercase leading-9 text-white md:leading-7 lg:text-4xl lg:leading-9">
                {t("pages.faq.title")}
              </h1>
              <p className="w-full text-center text-base leading-6 text-white md:w-10/12">
                {t("pages.faq.subtitle")}
              </p>
            </div>
            <div className="flex flex-wrap justify-center w-full pt-10">
              {categories.map((c, index) => {
                return (
                  <div
                    key={index}
                    className={classNames(
                      selected === c ? "border-gray-300" : "",
                      "w-full md:w-1/2 lg:w-1/3 xl:w-1/5 flex flex-col border border-transparent text-center focus:border-gray-800 py-3 md:px-3 cursor-pointer"
                    )}
                    onClick={() => {
                      categories.map((ca) => (ca.current = false));
                      if (selected === c) {
                        setSelected(null);
                        setSearchText("");
                      } else {
                        setSelected(c);
                        c.current = true;
                        scrollToRef(questionsRef);
                      }
                    }}
                  >
                    <div className="w-full flex flex-col space-y-6 py-6 px-12 bg-gray-50 rounded-lg">
                      <img src={c.icon} alt={c.id} className="h-20"/>
                      <p className="text-xl font-medium leading-5 text-gray-800">
                        {t(c.content)}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="w-full py-10 md:w-1/2 xl:w-1/3">
              <div
                className="relative mt-1 flex items-center"
                ref={questionsRef}
              >
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={searchText}
                  className="block w-full rounded-lg bg-white py-2 px-4 focus:ring-black sm:text-sm border-white focus:outline-0 text-black"
                  placeholder={t("general.actions.search")}
                  onChange={(event) => {
                    setSearchText(event.target.value);
                    setSelected(null);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center gap-8 pt-2 pb-12">
            {
              selected?.id === "Manuals" ?
                <div className="w-full">
                  <Manuals t={t} i18n={i18n} scrollToRef={scrollToRef} videos={videos}/>
                </div>
                : <div className="w-full lg:w-1/2">
                  <FAQAccordion
                    categories={selected ? selected.id : "null"}
                    t={t}
                    searchText={searchText}
                  />
                </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};
