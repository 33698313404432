import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import {useTranslation} from 'react-i18next';

// VIEWS
import Home from "./views/Home";
import NotFound from "./views/NotFound";
import Individual from "./views/Individual";
import Fleet from "./views/Fleet";
import OEM from "./views/OEM";
import MenuPage from "./views/Menu";
import BugReport from "./views/BugReport";
import CGU from "./views/Footer/CGU";
import LegalMentions from "./views/Footer/LegalMentions";
import Press from "./views/Footer/Press";
import PrivacyPolicy from "./views/Footer/PrivacyPolicy";

import wow from "./_helpers/wow.js";
import "./css/animations.css";
import "./css/App.css";

import { CSSTransition } from "react-transition-group";
import Navbar from "./components/Navbar";
import { FAQ } from "./views/FAQ/FAQ";

const RouteWithHeader = ({ path, component, transparent = false }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const refMenu = useRef();

  return (
    <div>
      <div>
        <CSSTransition
          nodeRef={refMenu}
          in={openMenu}
          timeout={700}
          classNames="my-node-enter"
          unmountOnExit
        >
          <MenuPage />
        </CSSTransition>

        <Navbar
          setOpen={setOpenMenu}
          open={openMenu}
          menu
          transparent={transparent || openMenu}
        />
      </div>
      <Route exact path={path} component={component} />
    </div>
  );
};

const App = () => {
  const location = useLocation();
  const {i18n} = useTranslation();

  useEffect(() => {
    new wow({
      live: false,
      scrollContainer: "body",
    }).init();
    console.log("wow");
  }, [location.pathname]);

  useEffect(() => {
    if (i18n && window.location.pathname.match('/en'))
      i18n.changeLanguage("en");
  }, [i18n]);

  return (
    <>
      <Switch>
        <RouteWithHeader exact path="/en" component={Home} />
        <RouteWithHeader exact path="/" component={Home} />
        <RouteWithHeader exact path="/individual/en" component={Individual} />
        <RouteWithHeader exact path="/individual" component={Individual} />
        <RouteWithHeader exact path="/fleet/en" component={Fleet} />
        <RouteWithHeader exact path="/fleet" component={Fleet} />
        <RouteWithHeader exact path="/oem/en" component={OEM} />
        <RouteWithHeader exact path="/oem" component={OEM} />
        <RouteWithHeader path="/faq/en" component={FAQ} />
        <RouteWithHeader path="/faq" component={FAQ} />
        <RouteWithHeader path="/bug_report/en" component={BugReport} />
        <RouteWithHeader path="/bug_report" component={BugReport} />
        <RouteWithHeader path="/404/en" component={NotFound} transparent />
        <RouteWithHeader path="/404" component={NotFound} transparent />
        <RouteWithHeader path="/cgu/en" component={CGU} />
        <RouteWithHeader path="/cgu" component={CGU} />
        <RouteWithHeader path="/privacy_policy/en" component={PrivacyPolicy} />
        <RouteWithHeader path="/privacy_policy" component={PrivacyPolicy} />
        <RouteWithHeader path="/press/en" component={Press} />
        <RouteWithHeader path="/press" component={Press} />
        <RouteWithHeader path="/legal_mentions/en" component={LegalMentions} />
        <RouteWithHeader path="/legal_mentions" component={LegalMentions} />
        <Redirect to="/404" />
      </Switch>
    </>
  );
};

export default App;
